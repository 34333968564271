@import "vars";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.news-item {
  #article-header {
    position: relative;
    overflow: hidden;

    .container {
      position: relative;
    }
  }

  .news-item-picture {
    max-width: 100%;
  }

  .publication-date {
    font-size: 1rem;
    font-weight: bold;
    color: black;
  }
  .update-date {
    font-size: .9rem;
    font-weight: bold;
    font-style: italic;
    color: $gray-600;
  }

  .news-description {
    font-size: 1.25rem;
    line-height: 1.6;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  h2, h3, h4 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  h2 {
    @include media-breakpoint-down(sm) {
     font-size: 1.25rem;
    }
  }

  h2:first-child {
    margin-top: 0;
  }

  h3 {
    font-size: 1.375rem;
    font-style: italic;
    font-weight: 500;
  }

  p {
    letter-spacing: -.4px;
    line-height: 1.79;
  }

  .banner.blue-gradient #breadcrumb, .banner.red-gradient #breadcrumb {
    position: absolute;
    margin : 0;
    top: 0;

    ol {
      border: none;
      padding: 0 3rem;
    }

    a {
      color: $light-grey;
      font-weight: 400;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      color: white;
    }
  }

  .offer-detail-item > div {
    max-width: 700px;
    width: 100%;
  }

  .breadcrumb-item.active {
    color: inherit;
    font-style: italic;
  }

  .banner.blue-gradient h1, .banner.red-gradient h1 {
    font-size: 3rem;
  }

  @include media-breakpoint-down(sm) {
    div[itemprop=articleBody] {
      img {
        width: 100%;
        height: 100%;
      }
      .col-picture img {
        height: 100px;
      }
    }
  }
}

.news-list {

  a:hover {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }

  .list-item-card {
    padding: 0 !important;
    height: 100%;
    transition: .8s;
    background-color: $white;
    border-radius: 30px;

    &:hover {
      box-shadow: 0 0 20px 0 $medium-grey;
    }

    .items-image {
      width:100%;
      position: relative;
      overflow: hidden;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $black;
      @include media-breakpoint-down(sm) {
        background-color: #676766;
      }

      img {
        position: relative;
        min-width: 100%;
        // min-height: 100%;  // TODO : vérifier tous les call css
        width: inherit;
        height: initial;
      }
    }

    .items-description {
      flex-grow: 1;
      padding: 2rem;
      @include media-breakpoint-down(sm) {
        font-size: small;
      }
    }

    .items-list {
      text-align: unset;
      @include media-breakpoint-down (sm) {
          color: white;
        }
    }

    &:hover img.card-img {
      transform: scale(1.2);
      transition: transform 500ms ease;
    }
  }
}

.news-item, .news-list {
  background-color: $white;

  #page-header, footer {
    background-color: $white;
  }

  .breadcrumb {
    background-color: transparent;
  }

  .container {
    overflow: hidden;
  }

  .pagination .page-link {
    background-color: transparent;

    &:hover {
      background-color: darken(#f3f3f1, 10%);
    }
  }
}

.not-list-disc {
  list-style: unset !important;
}

.news-category-link {
  font-size: 1em;
  color: white;
  border: 2px solid black;
  border-radius: 100px;
  background-color: #001836;
  padding: 1.5rem;
  margin-left: 3rem;
  @include media-breakpoint-down(sm) {
    font-size: .8em;
    margin-left: 0;
  }

  &:hover {
    color: $azure-blue;
  }
}

.news-category-title {
  color: black;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 2.5rem;
  margin-left: 3rem;
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
    margin-left: 0;
  }
}

.news-footer-link {
  margin-top: -40px;
  margin-bottom: 30px;

  h4 {
    font-size: 2rem;
    color: black;
  }

  a {
    font-size: 1rem;
    padding: 1rem;
    margin: 0.75rem 0;
    background-color: $testimony-blue-op20;
    &:hover {
      background-color: #bfbfbb;
    }
  }

}

.summary-faq-box {
  background: $light-grey;
  margin: 0 1rem;
  padding: 1rem;
  border-radius: 10px;

  p {
    font-size: 1.2rem;
    font-weight: bold;
  }
  a {
    text-decoration: none;
    margin-bottom: .5rem;
    color: #08f;
    display: block;
  }
}

#faq-news-list {

  .box-gray {
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,.1607843137);
  }
  li {
    margin-top: 0.5rem;
    list-style: disc;
  }
  ul {
    list-style: none;
  }
  .news-at {
    cursor: pointer;
    color: $azure-blue;
  }
  .selected-news {
    color: $medium-grey;
  }
  @include media-breakpoint-down(lg) {
    .news-item-picture {
      width: 100%;
    }
  }
  div.result div[itemprop=articleBody] {
    @include media-breakpoint-up(lg) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .scroll-top-news {
    position: fixed;
    bottom: 80px;
    right: 15px;
    z-index: 101;

    img {
      width: 40px;
      cursor: pointer;
    }
  }
  .result {
    h2, h3, h4, p {
      color: black;
    }
    h2 {
      font-size: 2rem;
    }
  }

  .category-card {
    padding: 0;
    .card-header {
      height: 300px;
    }
    &.top-quotation {
      border-radius: 20px;
      padding: 2rem;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    &.bottom-quotation {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }
}

#ressources-bloc {
  padding-top: 5rem;
  padding-bottom: 5rem;
  @include media-breakpoint-down (xs){

  }
  h2 {
    font-size: 2rem;
    color: black;
    font-weight: 700;
    font-style: unset;
    padding-left: 5rem;
    margin-bottom: 0;
    @include media-breakpoint-down (xs){
    padding-left: 0;
    }
  }
  .box-ressources {
    box-shadow: 0 3px 6px rgba(0,0,0,.1607843137);
    border-radius: 30px;
    background: white;
    color: black;
    @include media-breakpoint-down (xs) {
      padding: 2rem;
      margin-bottom: 2rem;
    }
    @include media-breakpoint-down (lg) {
      padding: 2rem;
      margin-bottom: 2rem;
    }
    p {
    text-transform: uppercase;
    color: #2653FB;
    font-weight: 800;
    font-size: 1.5rem;
    }
    h3 {
      font-size: 1.25rem;
      color: black;
      font-weight: 400;
      font-style: unset;
    }
    .btn {
      text-transform: uppercase;
      width: 15rem;
    }
  }
}

.pro-product-cta {
  background: #001836;
  padding: 5rem;
  @include media-breakpoint-down(xs) {
    padding: 2rem;
  }
}

#pro_products_cta {
  cursor: pointer;
  color: $white;
  .h4 {
    color: $white;
    font-size: 2rem;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: .5rem;
    width: 300px;

    & > div #cta_img{
      width: 100%;
    }
  }
}

.category-header {
  padding: 2rem;
  margin-top: 2rem;
  @include media-breakpoint-down(sm) {
    padding: 0;
    padding-top: 1rem;
    margin-left: 1rem;
  }
}

.items-image {
  .card-img {
    @include media-breakpoint-down(sm) {
      opacity: 0.6;
    }
  }
}

.items-description .headline {
  font-weight: bold;
  font-style: normal;
  text-align: left;
  @include media-breakpoint-down(sm) {
    font-size: medium;
    color: white;
    margin-bottom: 3rem;
  }
}

.items-description {
  @include media-breakpoint-down(sm) {
    color: white;
    position: absolute;
    margin-bottom: 15px;
  }
}

.items-description .description {
  text-align: left;
  line-height: initial;
}

.items-image .items-list {
  padding: 0!important;
  margin-top: 40px;
}

.news-ribbon {
  position: absolute;
  margin-top: 100px;
  width: 100%;
  z-index: -1;
  @include media-breakpoint-down(xl) {
    top: 100px;
  }
  @include media-breakpoint-down(lg) {
    top: 300px;
  }
  @include media-breakpoint-down(md) {
    top: 350px;
  }
}


.mag-box {
  background-color: $white;
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  margin: 2rem 0 2rem 4rem;
  padding: 3rem!important;
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
    margin-left: 1.5rem;
    padding: 2rem!important;
  }
}

.mag-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #2653FB;
  font-weight: 800;
  @include media-breakpoint-down(sm) {
    font-size: 2.25rem;
    margin-bottom: 0;
  }
}

.mag-text {
  font-size: medium;
  margin: 0;
  color: black;
  @include media-breakpoint-down(sm) {
    font-size: small;
  }
}

.section-container {
  margin: 5rem 0 10rem;
  @include media-breakpoint-up(xl) {
    margin-bottom: 20rem;
  }
  @include media-breakpoint-down(sm) {
    margin: 2rem 0 0;
  }
}

.section-title {
  margin: 0 0 1rem 4rem;
  font-size: 1.75rem;
  font-weight: 800;
  color: black;
  @include media-breakpoint-down(sm) {
    margin-left: 2rem;
    font-size: 2rem;
  }
}

.category-container {
  height: 290px;
}

.square-card {
  width: 250px;
  height: 250px;
  margin: 2rem 2rem 0 2rem;
  background-color: $white;
  box-shadow: 0 0 20px #becbfa;
  border-radius: 30px;
  padding: 1rem;
  transition: .8s;
  &:nth-child(5n) {
    margin-right: 2rem;
  }
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: black;
  z-index: 1;
  @include media-breakpoint-down(sm) {
    width: 200px;
    height: 200px;
    margin-left: 2rem;
  }

  span {
    text-align: left;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 22px;
  }

  &:hover {
    box-shadow: inset 0 0 10px #2653FB;
    color: #2653FB;
  }
}

.slider-news {

  .slide-news {
    display: flex;
    flex-shrink: 0;
      @include media-breakpoint-down(sm) {
        width: 70% !important;
      }
  }
}

// ---------------------- /news/detail.html --------------------------------

.news-main-picture {
  width: 100%;
  @include media-breakpoint-up(md) {
    padding: 0 15px;
  }
  @include media-breakpoint-between(sm, md) {
    width: 50%;
    margin: 1rem auto;
  }
}
#gutter-summary {
  position: sticky;
  top: 6rem;
  .summary-box {
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,.1607843137);

  .job-sheet-item & {
    padding-top: 0;
  }
}
}

.summary-label {
  width: 100%;
  background-color: #2653FB;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
  padding: 2rem;
}

.news-item #article-to-scroll.container-fluid {
  .row {
    @include media-breakpoint-down (xl){
      padding: 5rem;
    }
    @include media-breakpoint-down (md){
      padding: 2rem;
    }
    @include media-breakpoint-down (xs){
      padding: 0rem;
    }
  }
}

.summary-title {
  display: block;
  font-weight: bold;
  color: black;
  padding-left: 2rem;
  text-decoration: none !important;

  &:hover {
    color: $testimony-blue;
  }
}

.facebook-share {
  border: none;
  background-color: transparent;
}

.linkedin-share {
  border: none;
  background-color: transparent;
}

.black-circle {
  height: 40px;
  width: 40px;
  background-color: black;
  border-radius: 50%;
  border: 1px solid $black;
  display: inline-block;
  color: white;
  padding: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: transparent;
    border: 1px solid #2653FB;
    color: #2653FB;
  }
}

@include media-breakpoint-down(md) {
  #article-to-scroll {
    span.h2#commentaires {
      font-size: 1.3rem;
    }
  }
}

.news-category-label {
  color: #2653FB;
  font-weight: bold;
  font-size: 1.5rem;
  @include media-breakpoint-down(md) {
    font-size: large;
    margin-top: 3rem;
  }
}

.news-title-detail {
  color: black!important;
  font-size: 2rem;
  @include media-breakpoint-down(md) {
    font-size: large;
    margin-top: 15px;
  }
}

.align-article-body {
  @include media-breakpoint-up(lg) {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

.article-body {
  color: black;
  font-size: 1rem;
  @include media-breakpoint-down(sm) {
    font-size: .875rem;
  }
  .news-description {
    @include media-breakpoint-down(sm) {
      font-size: large;
    }
    margin-bottom: 5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
}

.iframe-podcast-mobile {
  @include media-breakpoint-down(md) {
    height: 450px!important;
  }
  @include media-breakpoint-down(xs) {
    height: 700px!important;
  }
}

.commentary-box {
  margin: 3rem 0;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    margin: 2rem 0 0 0;
  }

  .btn-azure {
    background-color: #2653FB !important;
    margin-bottom: 1rem;
  }
}

.same-topic-title {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  margin-left: 5rem;
  @include media-breakpoint-down(md) {
    font-size: large;
    margin-left: 2rem;
  }
}

// progress bar on scroll
.pb-container {
  width: 100%;
  height: 7px;
  background: #e7e7e3;
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
}

.pb-on-scroll {
  height: 100%;
  background: #2653FB;
  width: 0;
}

// ---------------------- floating call to action quotation --------------------------------
.floating-square {
  position: fixed;
  top: 70%;
  right: 10px;
  width: 180px;
  height: 160px;
  padding: 10px 15px;
  color: $white;
  border-radius: 10px;
  background-color: $unbounce-red;
  box-shadow: $default-shadow;
  z-index: 101;
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .absolute-h-top-center {
    transform: translate(-50%,0);
    width: 100%;
    span {
      display: block;
      font-size: 0.8rem;
      text-transform: uppercase;
      i {
        font-size: 2rem;
      }
    }
  }
  p {
    font-weight: 600;
    line-height: 1.2;
    margin: 60px 0 10px;
  }
  .btn {
    width: 90%;
    border: 2px solid $white;
    border-radius: 20px;
    background-color: $unbounce-red;
    color: $white;
    text-transform: uppercase;
    line-height: 1.3;
    &:hover {
      color: $unbounce-red;
      background-color: $white;
    }
  }
}

.floating-square-mobile {
  z-index: 101;
  width: 100%;
  height: 70px;
  color: $white;
  background-color: $unbounce-red;
  box-shadow: 0px -3px 6px #0000004D;
  bottom: 0;
  position: fixed;
  @include media-breakpoint-up(md) {
    display: none !important;
  }
  &:hover {
    color: $white;
  }
  i {
    font-size: 2.5rem;
  }
  p {
    text-transform: uppercase;
  }
}


.job-sheet-item {
  color: black;

  p {
    color: black;
  }

  h1 {
    font-size: 1.7rem;
    font-weight: 900;
    color: white;
    margin-bottom: 1.7rem;
  }

  .chapter-title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  h2, h3, h4 {
    color: black;
  }

  h2:not(.chapter-title, .same-topic-title), .wage {
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    font-style: unset;
  }

  .job-sheet-content {
    margin-bottom: 7rem;
  }

  .jobsheet-intro {
    background-color: rgba($electric-blue, 55%);
    border-radius: 25px;
    color: white;
    font-size: .76rem;
    font-weight: 600;
    display: flex;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-right: -15px;
      margin-left: -15px;
      border-radius: 25px 25px 0 0;
      display: block;
    }

    .news-main-picture {
      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: -10;
      padding: 0;
      margin: 0;
    }

    p {
      color: white;
      font-size: .76rem;
    }

    .jobsheet-guide-button {
      background-color: black;
      color: white;
      text-transform: uppercase;
      font-weight: 900;
      display: inline-block;
      padding: 1rem;
      border-radius: 0 0 25px;

      i {
        background-color: $electric-blue;
        padding: .3rem;
        border-radius: 50%;
        font-weight: 600;
        font-size: 1.2rem;
      }
    }

    &-text {
      padding: 2rem 2rem 3rem;
    }

    .jobsheet-subcategory {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $electric-blue;
      width: 100%;
      min-width: 80px;
      max-width: 250px;
      font-size: 4rem;
      border-radius: 0 25px 25px 0;
      padding: 2rem;
      @include media-breakpoint-down(md) {
        max-width: unset;
        border-radius: 0;
      }
    }
  }

  #job_sheet_info {
    padding-bottom: 3rem;
    @include media-breakpoint-down(md) {
      padding-bottom: 7rem;
    }

    .col {
      padding-right: 7px;
      padding-left: 7px;
    }

    .job-sheet-info-col {
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: .7rem 2rem;
      height: 100%;
      font-size: .76rem;
      @include media-breakpoint-down(lg) {
        font-weight: 500;
        padding: 1rem;
      }

      i {
        font-size: 3rem;
      }

      .chapter-title {
        margin-top: .7rem;
        margin-bottom: .5rem;
        text-transform: uppercase;
        color: black;
        font-weight: 900;
        font-size: 1.4rem;
        @include media-breakpoint-down(lg) {
          font-size: 1rem;
        }
      }

      .job-sheet-info-qualifications {
        color: $electric-blue;
        font-weight: 800;
        font-size: 1rem;
      }

      .job-sheet-info-values {
        color: $electric-blue;
        font-weight: 800;
      }
    }
    .col:nth-child(even) .job-sheet-info-col {
      background: white;
      box-shadow: inset 0px 0px 10px #00000029;
    }
    .col:nth-child(odd) .job-sheet-info-col {background: rgba($electric-blue, 20%)}
  }

  .job-sheet-qualifications {
    background-color: $electric-blue;
    color: white;
    p, h2, h3, h4, h5 {
      color: white;
    }
    border-radius: 20px;
    padding: 2rem 4rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    @include media-breakpoint-down(md) {
      border-radius: 0;
      padding: 6rem 2rem;
      margin: 7rem -15px 11rem;
    }
    .training-card-grid .col-description h3 {
      margin: 0 0 0.5rem;
    }
  }

  .job-sheet-skills {
    background-color: #F9F9FA;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 2rem 4rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    @include media-breakpoint-down(md) {
      border-radius: 0;
      margin-right: -15px;
      margin-left: -15px;
      padding: 6rem 2rem;
      box-shadow: 0px 6px 6px #00000029, 0px -6px 6px #00000029;
      margin-top : 7rem;
      margin-bottom : 11rem;
    }
  }


  .job-sheet-advantages {
    background-color: #F9F9FA;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 2rem 4rem;
    height: 100%;
    h3 {
      font-weight: 900;
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(sm) {
      border-radius: 0;
      margin-right: -15px;
      margin-left: -15px;
      padding: 6rem 2rem;
      box-shadow: 0px 6px 6px #00000029, 0px -6px 6px #00000029;
      margin-top : 0;
      margin-bottom : 0;
    }
  }

  #salaire {
    margin-top : 5rem;
    @include media-breakpoint-down(md) {
      margin-top : 7rem;
      margin-bottom : 11rem;
    }
  }

  .job-sheet-highlight {
    background-color: $electric-blue;
    color: white;
    p, h2, h3, h4, h5 {
      color: white;
    }
    border-radius: 10px;
    padding: 2rem 4rem;
    margin-top: 5rem;
    margin-bottom: 4rem;
    @include media-breakpoint-down(md) {
      border-radius: 0;
      margin-right: -15px;
      margin-left: -15px;
      padding: 6rem 2rem;
      margin-top: 7rem;
      margin-bottom: 7rem;
    }
  }

  .job-sheet-table {
    margin-top: 5rem;
    table {
      border: 1px solid #707070;
      width: 100%;
      tr:first-child {
        color: white;
        background-color: $testimony-blue;
      }
      td {
        border: 1px solid #707070;
        padding: .2rem 1rem;
      }
      td:first-child {
        font-weight: 700;
      }
    }
  }
}

.news-job-banner {
  background-image: linear-gradient(90deg,#57bbc8,#37b4c4 43%,#2c5e64);
  margin-top: 2rem;
  margin-bottom: 8rem;
  padding: 8rem !important;
  color: white;
  @include media-breakpoint-down(sm) {
    padding: 4rem !important;
  }

  h2 {
    font-size: 2rem;
    font-weight: 800;
    color: white;
  }
}

// Page category list

#header-category-list {
  text-align: left;
  padding: 3rem;
  @include media-breakpoint-down(sm) {
    padding: 1.5rem;
  }
  p {
    font-style: normal;
    font-weight: unset;
    font-size: 1rem;
    line-height: unset;
  }
}

.category-list {

  .card {
    border-radius: 20px;
    color: black;
    box-shadow: 0 4px 6px rgba(0,0,0,.1607843137);
    img {
      border-radius: 20px 0 0 20px;
      max-height: none;
      height: 100%;
      @include media-breakpoint-down (sm) {
        border-radius: 20px 20px 0 0;
      }
    }

    .card-body {
      padding: 1.5rem;
    }
    .card-title {
      font-size: 1.25rem;
      color: #2653fb;
    }
  }
  &.bloc-guides {
    border-radius: 30px;
    padding: 3rem;
    background-color: $testimony-blue-op20;
    margin-top: 3rem;
    margin-bottom: 3rem;
    h2 {
      font-weight: 700;
    }
  }
}

.news-comment-answer-card{
  margin-left: 6%;
  border-left: solid 6px $electric-blue;
  background-color: $gray-200;
  &.news-podcast{
      border-color: $pink-podcast;
    .news-comment-answer-card-member{
      color: $pink-podcast;
    }
  }
  &-member{
    color: $electric-blue;
  }
}

.training-banner {
  background-color: $pro-blue;
  padding: 2rem 3rem;
  font-size: 1.25rem;
  .btn-gold:hover {
    border: 1px solid gold;
    color: gold;
  }
}
